export const Back = ({ className, fillColor = "white", ...props }) => {
  return (
    <svg
      className={className}
      width="19"
      height="34"
      viewBox="0 0 19 34"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props?.onClick}
    >
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3044 0.711312C19.2319 1.65973 19.2319 3.19741 18.3044 4.14583L5.73376 17L18.3044 29.8542C19.2319 30.8026 19.2319 32.3403 18.3044 33.2887C17.3769 34.2371 15.8731 34.2371 14.9456 33.2887L0.695621 18.7173C-0.231874 17.7688 -0.231874 16.2312 0.695621 15.2827L14.9456 0.711312C15.8731 -0.237104 17.3769 -0.237104 18.3044 0.711312Z"
        fill={`${fillColor}`}
      />
    </svg>
  );
};
