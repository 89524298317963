export const Pills = ({ pill, openModal }) => {
  return (
    <div
      className={`${pill.icon} ${pill.modal ? 'learn-more' : 'no-learn-more'}`}
      {...(pill.modal && { onClick: () => openModal(pill.infoModal) })}
    >
      <img src={pill.src} alt={pill.icon} />
      <hr />
      <h1>{pill.name}</h1>
      {pill.modal && <span>Learn more</span>}
    </div>
  );
};

export default Pills;
