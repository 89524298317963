export const PowerGeneration = ({ fill = "#292E3D" }) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M4.76391 34.9098C4.13391 34.9098 3.56391 34.5798 3.24391 34.0298C2.92391 33.4798 2.92391 32.8198 3.24391 32.2798L18.4839 5.87976C18.8039 5.32976 19.3639 4.99976 20.0039 4.99976C20.6439 4.99976 21.2039 5.32976 21.5239 5.87976L36.7639 32.2798C37.0839 32.8298 37.0839 33.4898 36.7639 34.0298C36.4439 34.5698 35.8839 34.9098 35.2439 34.9098H4.76391ZM17.5039 28.8098C17.4339 29.2798 17.7039 29.7398 18.1439 29.8998C18.2539 29.9398 18.3739 29.9598 18.4939 29.9598C18.8339 29.9598 19.1539 29.7898 19.3439 29.4898L24.4739 21.1998C24.6639 20.8898 24.6739 20.4998 24.4939 20.1898C24.3139 19.8698 23.9839 19.6798 23.6239 19.6798H21.7939L22.4739 15.8298C22.5539 15.3698 22.3039 14.9198 21.8839 14.7398C21.7539 14.6898 21.6239 14.6598 21.4939 14.6598C21.1839 14.6598 20.8839 14.8098 20.6839 15.0698L15.5939 22.0298C15.3739 22.3298 15.3339 22.7398 15.5139 23.0698C15.6839 23.4098 16.0239 23.6198 16.4039 23.6198H18.3239L17.5139 28.8198L17.5039 28.8098Z"
        fill={fill}
      />
      <path
        d="M20.0038 5.99976C20.1638 5.99976 20.4638 6.04976 20.6538 6.37976L35.8938 32.7798C36.0838 33.1098 35.9738 33.3898 35.8938 33.5298C35.8138 33.6698 35.6238 33.9098 35.2438 33.9098H4.76381C4.38381 33.9098 4.19381 33.6698 4.11381 33.5298C4.03381 33.3898 3.92381 33.0998 4.11381 32.7798L19.3538 6.37976C19.5438 6.04976 19.8438 5.99976 20.0038 5.99976ZM16.3838 24.6098H17.1438L16.5138 28.6498C16.3638 29.5898 16.9038 30.4998 17.7938 30.8298C18.0238 30.9098 18.2538 30.9498 18.4838 30.9498C19.1638 30.9498 19.8138 30.6098 20.1838 29.9998L25.3138 21.7098C25.6938 21.0898 25.7138 20.3198 25.3638 19.6798C25.0138 19.0498 24.3438 18.6498 23.6138 18.6498H22.9738L23.4438 15.9698C23.6038 15.0498 23.1138 14.1498 22.2538 13.7798C22.0038 13.6698 21.7338 13.6198 21.4738 13.6198C20.8538 13.6198 20.2438 13.9098 19.8638 14.4398L14.7738 21.3998C14.3338 22.0098 14.2638 22.8098 14.6038 23.4898C14.9438 24.1598 15.6338 24.5898 16.3838 24.5898M20.0038 3.99976C19.0738 3.99976 18.1538 4.45976 17.6238 5.37976L2.37381 31.7798C1.31381 33.6198 2.63381 35.9098 4.75381 35.9098H35.2338C37.3538 35.9098 38.6738 33.6198 37.6138 31.7798L22.3838 5.37976C21.8538 4.45976 20.9238 3.99976 20.0038 3.99976ZM21.4838 15.6498L20.6038 20.6798H23.6238L18.4938 28.9698L19.4738 22.6198H16.3838L21.4738 15.6598L21.4838 15.6498Z"
        fill={fill}
      />
    </svg>
  );
}