import styled from "styled-components";
import { Contact, Facebook, Instagram, Linkedin, Location } from "./ui/icons";
import { devices } from "../utils/devices";

export const Footer = () => {
  return (
    <FooterBox>
      <div className="footer__social">
        <h3>Social Media</h3>
        <div className="footer__social__media__logos">
          <a
            href="https://www.facebook.com/profile.php?id=100090824573027&locale=es_LA"
            target="_blank"
            title="facebook"
            rel="noreferrer"
          >
            <Facebook />
          </a>
          <a
            href="https://www.instagram.com/dynamic_reinsurance/"
            target="_blank"
            title="instagram"
            rel="noreferrer"
          >
            <Instagram />
          </a>
          <a
            href="https://www.linkedin.com/company/dynamic-reinsurance-llc/"
            title="linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin />
          </a>
        </div>
      </div>

      <div className="footer__contact">
        <h3>Contact</h3>
        <div>
          <Contact />
          <span>concierge@dynamicreinsurance.com.</span>
        </div>
      </div>

      <div className="footer__logo">
        <img src="/images/logos/logo_dark.svg" alt="logo_dark" />
        <h3>© Dynamic Reinsurance 2023. All Rights Reserved</h3>
        <a
          href="https://dynamicreinsurance.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policies
        </a>
      </div>

      <div className="footer__location">
        <h3>Location</h3>

        <div className="footer__location__addresses">
          <div className="footer__location__addresses__box">
            <Location />
            <p>
              <h4>Miami</h4> 1395 Brickell Ave. Suite 800 Miami, Florida 33131
            </p>
          </div>
          <div className="footer__location__addresses__box">
            <Location />
            <p>
              <h4>Mexico City</h4> Anillo Periférico 3720 Jardines del Pedregal
              Álvaro Obregón, 01900
            </p>
          </div>
        </div>
      </div>

      <hr />
    </FooterBox>
  );
};

export default Footer;

const FooterBox = styled.footer`
  display: grid;
  grid-template-areas:
    "logo"
    "contact"
    "location"
    "social"
    "border";
  grid-template-columns: 1fr;
  grid-row-gap: 5rem;
  align-items: center;
  padding: 5rem 2rem;

  hr {
    grid-area: border;
    margin-top: 2rem;
    border: 1px solid var(--gray-20);
  }

  .footer__social {
    display: grid;
    grid-area: social;
    place-items: center;

    h3 {
      font-size: 1.2em;
      margin-bottom: 1rem;
      color: var(--gray-200);
    }

    .footer__social__media__logos {
      display: flex;
      gap: 1.3rem;
    }
  }

  .footer__contact {
    display: grid;
    place-items: center;
    grid-area: contact;

    h3 {
      text-align: center;
      font-size: 1.2em;
      margin-bottom: 1rem;
      color: var(--gray-200);
    }

    div {
      display: flex;
      gap: 1rem;
      align-items: center;

      span {
        font-size: 1em;
        font-weight: 400;
        color: var(--gray-100);
      }
    }
  }

  .footer__logo {
    grid-area: logo;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    img {
      width: 90px;
    }

    h3 {
      font-size: 1em;
      font-weight: 400;
      color: var(--gray-100);
    }

    a {
      font-size: 1em;
      color: var(--gray-100);
      font-weight: 400;
      text-decoration-line: underline;
    }
  }

  .footer__location {
    grid-area: location;

    h3 {
      font-size: 1.2em;
      margin-bottom: 1rem;
      color: var(--gray-200);
    }

    .footer__location__addresses__box {
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;

      p {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        font-size: 1em;
        font-weight: 400;
        color: var(--gray-100);
      }
    }
  }

  @media screen and (${devices.laptop}) {
    grid-template-columns: 1fr 2fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    padding: 5rem 7rem;
    grid-template-areas:
      "social logo location"
      "contact logo location"
      "border border border";

    .footer__social {
      grid-area: social;
      place-items: flex-start;

      h3 {
        text-align: left;
      }
    }

    .footer__contact {
      place-items: flex-start;

      h3 {
        text-align: left;
      }
    }
  }
`;
