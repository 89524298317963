export const Instagram = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ph:instagram-logo-fill">
        <path
          id="Vector"
          d="M22.5 18C22.5 18.89 22.2361 19.76 21.7416 20.5001C21.2471 21.2401 20.5443 21.8169 19.7221 22.1575C18.8998 22.4981 17.995 22.5872 17.1221 22.4135C16.2492 22.2399 15.4474 21.8113 14.818 21.182C14.1887 20.5526 13.7601 19.7508 13.5865 18.8779C13.4128 18.005 13.5019 17.1002 13.8425 16.2779C14.1831 15.4557 14.7599 14.7529 15.4999 14.2584C16.24 13.7639 17.11 13.5 18 13.5C19.1923 13.5037 20.3348 13.979 21.1779 14.8221C22.021 15.6652 22.4963 16.8077 22.5 18ZM32.0625 11.8125V24.1875C32.0625 26.2761 31.2328 28.2791 29.756 29.756C28.2791 31.2328 26.2761 32.0625 24.1875 32.0625H11.8125C9.72392 32.0625 7.72088 31.2328 6.24403 29.756C4.76719 28.2791 3.9375 26.2761 3.9375 24.1875V11.8125C3.9375 9.72392 4.76719 7.72088 6.24403 6.24403C7.72088 4.76719 9.72392 3.9375 11.8125 3.9375H24.1875C26.2761 3.9375 28.2791 4.76719 29.756 6.24403C31.2328 7.72088 32.0625 9.72392 32.0625 11.8125ZM24.75 18C24.75 16.665 24.3541 15.3599 23.6124 14.2499C22.8707 13.1399 21.8165 12.2747 20.5831 11.7638C19.3497 11.2529 17.9925 11.1192 16.6831 11.3797C15.3738 11.6402 14.171 12.283 13.227 13.227C12.283 14.171 11.6402 15.3738 11.3797 16.6831C11.1192 17.9925 11.2529 19.3497 11.7638 20.5831C12.2747 21.8165 13.1399 22.8707 14.2499 23.6124C15.3599 24.3541 16.665 24.75 18 24.75C19.7902 24.75 21.5071 24.0388 22.773 22.773C24.0388 21.5071 24.75 19.7902 24.75 18ZM27 10.6875C27 10.3537 26.901 10.0275 26.7156 9.74998C26.5302 9.47247 26.2666 9.25618 25.9583 9.12845C25.6499 9.00073 25.3106 8.96731 24.9833 9.03242C24.6559 9.09754 24.3553 9.25826 24.1193 9.49426C23.8833 9.73026 23.7225 10.0309 23.6574 10.3583C23.5923 10.6856 23.6257 11.0249 23.7535 11.3333C23.8812 11.6416 24.0975 11.9052 24.375 12.0906C24.6525 12.276 24.9787 12.375 25.3125 12.375C25.7601 12.375 26.1893 12.1972 26.5057 11.8807C26.8222 11.5643 27 11.1351 27 10.6875Z"
          fill="#3BABF1"
        />
      </g>
    </svg>
  );
};
