export const Claims = ({ fill = "#292E3D" }) => {
  return (
    <svg
      width="36"
      height="31"
      viewBox="0 0 36 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 3154">
        <path
          id="Vector"
          opacity="0.5"
          d="M19.18 22.07L20.27 17.98L25.9 12.35V2.62C25.9 2 25.4 1.5 24.78 1.5H2.12C1.5 1.5 1 2 1 2.62V28.58C1 29.5 2.06 30.03 2.79 29.47L6.55 26.62C6.74 26.47 6.98 26.39 7.23 26.39H24.78C25.4 26.39 25.9 25.89 25.9 25.27V18.35L23.27 20.98L19.18 22.07Z"
          fill={fill}
        />
        <path
          id="Vector_2"
          d="M21.5106 9.35156H5.39062C4.84062 9.35156 4.39062 8.90156 4.39062 8.35156C4.39062 7.80156 4.84062 7.35156 5.39062 7.35156H21.5206C22.0706 7.35156 22.5206 7.80156 22.5206 8.35156C22.5206 8.90156 22.0706 9.35156 21.5206 9.35156H21.5106Z"
          fill={fill}
        />
        <path
          id="Vector_3"
          d="M35.36 7.21L34.05 5.9C33.19 5.04 31.79 5.04 30.94 5.9L29.2 7.64L26.9 9.94V2.62C26.9 1.45 25.95 0.5 24.78 0.5H2.12C0.95 0.5 0 1.45 0 2.62V28.58C0 29.39 0.45 30.12 1.18 30.48C1.48 30.63 1.8 30.7 2.13 30.7C2.58 30.7 3.03 30.55 3.41 30.27L7.24 27.4H24.79C25.96 27.4 26.91 26.45 26.91 25.28V18.77L33.62 12.05L35.36 10.31C36.22 9.45 36.22 8.06 35.36 7.2V7.21ZM24.91 25.29C24.91 25.36 24.86 25.41 24.79 25.41H7.23C6.77 25.41 6.32 25.56 5.95 25.84L2.19 28.69C2.19 28.69 2.13 28.73 2.07 28.7C2 28.67 2 28.62 2 28.59V2.62C2 2.55 2.05 2.5 2.12 2.5H24.78C24.85 2.5 24.9 2.55 24.9 2.62V11.94L22.47 14.37C22.48 14.3 22.51 14.24 22.51 14.17C22.51 13.62 22.06 13.17 21.51 13.17H5.39C4.84 13.17 4.39 13.62 4.39 14.17C4.39 14.72 4.84 15.17 5.39 15.17H21.52C21.59 15.17 21.65 15.14 21.72 15.13L19.57 17.28C19.45 17.4 19.36 17.56 19.31 17.73L18.98 18.99H5.39C4.84 18.99 4.39 19.44 4.39 19.99C4.39 20.54 4.84 20.99 5.39 20.99H18.44L18.22 21.82C18.13 22.16 18.22 22.53 18.48 22.78C18.56 22.86 18.66 22.93 18.77 22.97C18.8 22.98 18.82 22.99 18.85 23C18.96 23.04 19.07 23.07 19.19 23.07C19.28 23.07 19.36 23.06 19.45 23.04L23.54 21.95C23.71 21.9 23.87 21.82 23.99 21.69L24.91 20.77V25.28V25.29ZM22.77 20.09L20.6 20.67L21.18 18.5L26.62 13.06L29.92 9.76L30.71 10.55L31.5 11.34L22.76 20.08L22.77 20.09ZM33.95 8.91L32.92 9.94L32.13 9.15L31.34 8.36L32.37 7.33C32.45 7.25 32.58 7.25 32.66 7.33L33.96 8.63C34.04 8.71 34.04 8.84 33.96 8.92L33.95 8.91Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}