export const Start = ({ className = "" }) => {
  return (
    <svg
      className={`${className}`}
      width="49"
      height="50"
      viewBox="0 0 49 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M0 25C0 11.4696 10.9696 0.5 24.5 0.5C38.0304 0.5 49 11.4696 49 25C49 38.5304 38.0304 49.5 24.5 49.5C10.9696 49.5 0 38.5304 0 25ZM29.6827 33.7328V16.2672C29.6824 15.9093 29.5802 15.5588 29.3881 15.2569C29.1959 14.9549 28.9218 14.7139 28.5977 14.562C28.2736 14.4102 27.913 14.3538 27.558 14.3994C27.203 14.445 26.8683 14.5907 26.5931 14.8195L16.0993 23.5512C15.8867 23.7281 15.7157 23.9495 15.5983 24.1999C15.4809 24.4503 15.42 24.7235 15.42 25C15.42 25.2765 15.4809 25.5497 15.5983 25.8001C15.7157 26.0505 15.8867 26.2719 16.0993 26.4488L26.5931 35.1805C26.8683 35.4093 27.203 35.555 27.558 35.6006C27.913 35.6462 28.2736 35.5898 28.5977 35.438C28.9218 35.2861 29.1959 35.0451 29.3881 34.7431C29.5802 34.4412 29.6824 34.0907 29.6827 33.7328Z"
        fill="#F1A63B"
      />
    </svg>
  );
};
