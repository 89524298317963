import styled, { keyframes } from "styled-components";
import { Close } from "./ui/icons/Close";
import { useState } from "react";
import { sizes } from "../utils/sizes";
import { devices } from "../utils/devices";

export const Modal = ({
  children,
  isOpen,
  closeModal,
  gridTemplateAreasMb,
  gridTemplateAreasLg,
  width
}) => {
  const [reverse, setReverse] = useState(false);
  const settings = {
    stylesCard: {
      width: window.innerWidth < sizes.laptop ? "85%" : width,
      gridTemplateAreas: window.innerWidth < sizes.laptop ?  gridTemplateAreasMb : gridTemplateAreasLg,
      gridTemplateColumns: window.innerWidth < sizes.laptop ? "1fr" : "0.7fr 1fr",
    }
  }

  if (!isOpen) return null;

  const handleCloseModal = () => {
    // set reverse animation pills to true
    setReverse(true);

    setTimeout(() => {
      // close property info
      closeModal();
      // set reverse animation pills to false
      setReverse(false);
    }, 600);
  }

  return (
    <ModalBg
      onClick={handleCloseModal}
    >
      <ModalCard
        className='modal-card'
        $reverse={reverse}
        onClick={e => e.stopPropagation()}
        style={{ ...settings.stylesCard }}
        
      >
        <button
          className="modal__close"
          onClick={handleCloseModal}
        >
          <div className='close-icon'>
          <Close />
          </div>
        </button>
        {children}
      </ModalCard>
    </ModalBg>
  );
};

export default Modal;

const animateModalCard = keyframes`
	from {
		transform: translateX(-100%);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
`;

const animateModalReverse = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

const ModalBg = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const ModalCard = styled.div`
  position: relative;
  display: grid;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  animation: ${({ $reverse }) => !$reverse ? animateModalCard : animateModalReverse} .8s ease;
  overflow: hidden;
  max-height: 90vh;
 
  .modal__close {
    position: absolute;
    top: 1rem;
    right: 2.5rem;
    background: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 2;

    .close-icon{
      .close{
        width: 25px;
        color: white;
      }
    }
  }

  @media screen and (${devices.tablet}) {
		max-height: 600px;
    .modal__close {
      top: 3rem;
      right: 3rem;
    .close-icon{
      .close{
        width: 45px;
      }
    }
  }
  }
    

`;
