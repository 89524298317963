import styled, { css } from "styled-components";
import { devices } from "../utils/devices";
import Services from "./ui/icons/Services";
import { useState, useRef } from "react";
import { MagicMotion } from "react-magic-motion";
import gsap from "gsap";
import { Actuarial, Claims, Underwriting } from "./ui/icons/OurServices/";
import { DataEngineering, InfoTech } from "./ui/icons/AddedValue";

export const ServiceActive = ({ serviceActive, handleServiceActive }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeIndexButton, setActiveIndexButton] = useState(null);
  const sliderContainerRef = useRef(null);

  const svgMap = {
    // Our Services
    "Claims": () => <Claims />,
    "Actuarial": () => <Actuarial />,
    "Underwriting": () => <Underwriting />,
    // Added Value
    "Data Engineering & Analytics": () => <DataEngineering />,
    "Information Technology": () => <InfoTech />,
  }

  if (serviceActive === null) return null;
  const condRender = serviceActive.identifier === "OUR_SERVICES";
  const condActive = activeIndex !== null;

  const gridContent = condRender
    ? `"title cards" "title cards"`
    : `"cards title" "cards title"`;

  const gridContentActive = condActive && `"title title" "cards cards"`;

  const handleMove = (e, index) => {
    setActiveIndex(index);
    setActiveIndexButton(index)

    const large = document.querySelector(".cards .container .large");
    if (large !== null) {
      large.className = "card small unactive";
    }

    e.currentTarget.className = "card list large";


    const contenedor = document.querySelector('.container');
    const scrollX = e.currentTarget.offsetLeft + e.currentTarget.offsetWidth / 2 - contenedor.offsetWidth / 2;
    contenedor.scroll({ left: scrollX, behavior: 'smooth', duration: 1000, });
  };

  const handleMoveDesktop = (e, identifier, index) => {
    setActiveIndex(index);

    const identifierKebab = identifier.replace(/_/g, "-").toLowerCase();
    const large = document.querySelector(
      `.cards .card-container-${identifierKebab} .large`
    );
    if (large !== null) {
      large.className = `card card-${identifierKebab} small`;
    }

    e.currentTarget.className = `card large list card-${identifierKebab}`;
  };

  const handleService = () => {
    setActiveIndex(null);
    setActiveIndexButton(null);

    gsap.fromTo(
      ".service-mobile .content",
      { opacity: 0, ease: "power2.inOut" },
      { opacity: 1, duration: 0.8, ease: "power2.inOut", delay: 0.3 }
    );

    gsap.fromTo(
      ".service-mobile .cards",
      { opacity: 0 },
      { opacity: 1, duration: 0.8, ease: "power2.inOut", delay: 0.3 }
    );

    gsap.fromTo(
      ".service-desktop .switch",
      { opacity: 0 },
      { opacity: 1, duration: 0.8, ease: "power2.inOut", delay: 0.3 }
    );

    handleServiceActive(condRender ? "ADDED_VALUE" : "OUR_SERVICES");
  };

  const moveSliderControl = (index) => {
    const currentCard = document.getElementById(index);
     const scrollX = currentCard.offsetLeft + currentCard.offsetWidth / 2 - sliderContainerRef.current.offsetWidth / 2;
    sliderContainerRef.current.scrollTo({
      left: scrollX,
      behavior: "smooth",
    });

    setActiveIndexButton(index);
  };


  return (
    <ServiceActiveBox $condRender={condRender} $activeIndex={activeIndex}>
      <div className="service-mobile">
        <div className="content">
          <img src={serviceActive.altIcon} alt={serviceActive.title} />
          <h1 className="content-title">{serviceActive.title}</h1>
        </div>

        <div className="cards">
          <div className="container" ref={sliderContainerRef}>
            <div className='inner-wrapper'>
              {serviceActive.cards.map((card, index) => (
                <div
                  key={`${card.title}-${serviceActive.identifier}`}
                  id={index}
                  className="card small list"
                  onClick={(e) => handleMove(e, index)}
                >
                  {activeIndex === index ? (
                    <ul>
                      <li className="item-info">{card.description}</li>
                    </ul>
                  ) : (
                    <>
                      {svgMap[card.icon]()}
                      <h1 className="card-content-title">{card.title}</h1>
                    </>
                  )}
                </div>
              ))}
            </div>

          </div>
          <div className="slider-controls">
        {serviceActive.cards.map((_, index) => {
          let bgColor = "#FFF";
          if (activeIndexButton === index) bgColor = "#f1a63b";
          return (
            <button
              key={index}
              type="button"
              title="slider-control-btn"
              className={`slider-control-btn`}
              onClick={() => moveSliderControl(index)}
              style={{ backgroundColor: bgColor }}
            />
          );
        })}
      </div>
        </div>

        


        <div className="switch">
          <div className="switch-btn">
            <img
              src="/images/services/icons/triangle.svg"
              alt="switch"
              onClick={handleService}
            />

            {condRender ? (
              <Services.AddedValueFilled className="icon" />
            ) : (
              <Services.OurServicesFilled className="icon" />
            )}
          </div>
        </div>
      </div>

      <div className="service-desktop">
        <MagicMotion
          transition={{
            type: "spring",
            duration: 1,
          }}>
          <div
            className={`content ${condActive ? "state-active" : ""}`}
            style={{
              gridTemplateAreas: condActive ? gridContentActive : gridContent,
              gridTemplateColumns: condActive,
            }}
          >
            <div className="title">
              {condRender ? (
                <Services.OurServices className="icon" fill="#FFF" />
              ) : (
                <Services.AddedValue className="icon" fill="#FFF" />
              )}
              <h1 key="exclude">{serviceActive.title}</h1>
            </div>
            <div className="cards">
              <div
                key="exclude"
                className={`card-container-${condRender ? "our-services" : "added-value"
                  }`}
              >
                {serviceActive.cards.map((card, index) => (
                  <div
                    key={`${card.title}-${serviceActive.identifier}`}
                    className={`card small  ${condRender ? `card-our-services` : "card-added-value"
                      }`}
                    onClick={(e) =>
                      handleMoveDesktop(e, serviceActive.identifier, index)
                    }
                  >
                    {activeIndex === index ? (
                      <>
                        <h2>{card.title}</h2>
                        <p>{card.description}</p>
                      </>
                    ) : (
                      <>
                        {svgMap[card.icon]()}
                        <h1 className="card-content-title">{card.title}</h1>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </MagicMotion>

        <div className="switch">
          <div className="switch-btn">
            <img
              src="/images/services/icons/triangle.svg"
              alt="switch"
              onClick={handleService}
            />

            {condRender ? (
              <Services.AddedValue className="icon" />
            ) : (
              <Services.OurServices className="icon" />
            )}
          </div>
        </div>
      </div>
    </ServiceActiveBox>
  );
};

export default ServiceActive;

const ServiceActiveBox = styled.div`
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;

  .service-mobile {
    display: grid;
    grid-template-columns: minmax(2rem, 1fr);
    grid-template-rows: 10rem 1fr 13rem;
    grid-template-areas: "content" "cards" "switch";
    height: 100%;

    .content {
      grid-area: content;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      img {
        height: 45px;
        width: 45px;
        margin-bottom: 20px;
      }

      .content-title {
        color: #fff;
        font-size: 1.4em;
        font-weight: 400;
        animation: fadein 0.5s ease-in-out;
      }
    }

    .cards {
      grid-area: cards;

      .container {
        position: relative;
        overflow-x: scroll;
        height: 60%;

        .inner-wrapper{
          position: relative;
          width: fit-content;
          height: 100%;
          display: flex;
          gap: 30px;
          padding: 0px 35px;
          align-items: center;
        }
      }

      .card {
        height: 200px;
        transition: all 1.2s ease;
        transition: background-color .5s ease, color .5s ease;
        ${'' /* position: absolute; */}
        background-color: #fff;
        display: flex;
        border-radius: 5px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background-color: var(--yellow-200);

          .card-content-title {
            color: var(--white);

            &:before {
              background-color: var(--white);
            }
          }
        }

        img {
          height: 50px;
          width: 50px;
          margin-bottom: 20px;
        }

        .card-content-title {
          color: #000;
          font-size: 1.2em;
          text-align: center;
          position: relative;
          margin: 0 1rem;

          &:before {
            content: "";
            background-color: var(--gray-100);
            position: absolute;
            width: 50%;
            margin: auto;
            top: -10px;
            left: 0;
            right: 0;
            height: 2px;
          }
        }
      }

      .card.list {
        ul,
        li {
          list-style: none;
        }

        .item-info {
          align-items: flex-start;
        }

        ul {
          margin: 2rem 2rem;
          padding: 1rem 0;
          position: relative;

          li {
            font-size: 1.2em;
            color: var(--white);
          }

          &:before {
            content: "";
            background-color: var(--white);
            position: absolute;
            width: 60px;
            top: 0;
            height: 2px;
          }
        }
      }

      .small {
        top: 10%;
        bottom: 10%;
        width: 70vw;
        ${'' /* left: 0; */}
      }

      .small ~ .small ~ .small {
        top: 10%;
        left: 45%;
      }

      .small ~ .small {
        top: 10%;
        left: ${({ $activeIndex, $condRender }) =>
    $activeIndex === null ? ($condRender ? "90%" : "50%") : "120%;"};
      }

      .large {
        top: 0;
        height: 100%;
        bottom: 0;
        width: 80vw;
        background-color: var(--yellow-200);
        left: 45%;
      }
    }

    .slider-controls {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;

    .slider-control-btn {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: var(--gray-50);
      border: none;
      margin: 0 0.3rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--blue-200);
      }
    }
  }

    .switch {
      grid-area: switch;
      position: relative;
      display: flex;
      margin-bottom: -3px;
      ${({ $condRender }) =>
    $condRender
      ? css`
              justify-content: end;
              align-items: flex-end;
            `
      : css`
              align-items: end;
              justify-content: start;
            `}

      .switch-btn {
        transition: all 1s ease-in-out;
        ${({ $condRender }) =>
    $condRender ? "transform: scaleX(1)" : "transform: scaleX(-1)"}
      }

      .icon {
        position: absolute;
        width: 70px;
        height: 70px;
        top: 30px;
        bottom: 5px;
        margin: auto;
        right: 3rem;
      }
    }

    .container::-webkit-scrollbar {
      display: none;
    }
  }

  .service-desktop {
    display: none;
  }

  @media screen and (${devices.laptop}) {
    top: 120px;

    .service-mobile {
      display: none;
    }

    .service-desktop {
      display: block;
      height: 500px;

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes slide1 {
        0% {
          opacity: 0;
          transform: translate(-300px, 1000px) rotate(50deg);
        }
        100% {
          transform: translate(0, 0) rotate(0deg);
          opacity: 1;
        }
      }

      @keyframes slide2 {
        0% {
          opacity: 0;
          transform: translate(-1700px, 1000px) rotate(-50deg);
        }
        100% {
          transform: translate(0, 0) rotate(0deg);
          opacity: 1;
        }
      }

      @keyframes slide3 {
        0% {
          opacity: 0;
          transform: translate(-1500px, 1000px) rotate(-50deg);
        }
        100% {
          transform: translate(0, 0) rotate(0deg);
          opacity: 1;
        }
      }

      @keyframes slide1b {
        0% {
          opacity: 0;
          transform: translate(1000px, 1000px) rotate(80deg);
        }
        100% {
          transform: translate(0, 0) rotate(0deg);
          opacity: 1;
        }
      }

      @keyframes slide2b {
        0% {
          opacity: 0;
          transform: translate(1000px, 1000px) rotate(-80deg);
        }
        100% {
          transform: translate(0, 0) rotate(0deg);
          opacity: 1;
        }
      }

      @keyframes title {
        0% {
          opacity: 0;
          transform: translate(-200px, 1000px);
        }
        100% {
          transform: translate(0, 0);
          opacity: 1;
        }
      }

      .content {
        user-select: none;
        display: grid;
        ${'' /* grid-template-columns: repeat(2, 1fr); */}
        grid-template-rows: repeat(2, 18rem);

        .title {
          grid-area: title;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          justify-self: center;
          align-self: center;

          h1 {
            animation: title 0.5s ease;
            margin-top: 2rem;
            color: var(--white);
            font-size: 3.5em;
            font-weight: 400;
          }
        }

        .cards {
          grid-area: cards;
          display: grid;
          align-items: center;
          justify-items: ${({ $condRender }) =>
    $condRender ? "start" : "right"};
          padding: ${({ $condRender }) =>
    $condRender ? "0 0 0 3rem " : "0 3rem 0 0"};

          .card-container-added-value {
            width: 80%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: "card1 card2";
            grid-column-gap: 3rem;
            height: 200px;
          }

          .card-container-our-services {
            width: 70%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas: "card1 card2 active";
            grid-column-gap: 1.5rem;
            height: 200px;
          }

          .card-added-value {
            opacity: 0.9;
            padding: 0 3rem;
            background-color: var(--white);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            transition: background-color .5s ease, color .5s ease, margin 0.5s ease, scale .5s ease;

            &:hover {
              background-color: var(--yellow-200);
              color: var(--white);
              cursor: pointer;
              scale: .9;

              .card-content-title {
                color: var(--white);

                &:before {
                  background-color: var(--white);
                }
              }

              svg path {
                fill: var(--white);
              }
            }

            img {
              height: 50px;
              width: 50px;
            }

            .card-content-title {
              font-size: 1.2em;
              position: relative;
              padding: 1.5rem 0;
              text-align: center;

              &:before {
                content: "";
                background-color: var(--gray-100);
                position: absolute;
                width: 50%;
                margin: auto;
                top: 10px;
                left: 0;
                right: 0;
                height: 2px;
              }
            }

            &:nth-child(1) {
              animation: slide1b 1.5s ease;
            }

            &:nth-child(2) {
              animation: slide2b 1.5s ease;
            }
          }

          .card-our-services {
            opacity: 0.9;
            padding: 0 3rem;
            background-color: var(--white);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            transition: background-color 0.5s, color 1s ease, margin 0.5s ease, scale .5s ease;

            &:hover {
              background-color: var(--yellow-200);
              color: var(--white);
              cursor: pointer;
              scale: .9;

              .card-content-title {
                color: var(--white);

                &:before {
                  background-color: var(--white);
                }
              }

              svg path {
                fill: var(--white);
              }
            }

            img {
              height: 50px;
              width: 50px;
            }

            .card-content-title {
              font-size: 1.2em;
              position: relative;
              padding: 1.5rem 0;

              &:before {
                content: "";
                background-color: var(--gray-100);
                position: absolute;
                width: 50%;
                margin: auto;
                top: 10px;
                left: 0;
                right: 0;
                height: 2px;
              }
            }

            &:nth-child(1) {
              animation: slide1 1.5s ease;
            }

            &:nth-child(2) {
              animation: slide2 1.5s ease;
            }

            &:nth-child(3) {
              animation: slide3 1.5s ease;
            }
          }
        }
      }

      .content.state-active {
        grid-template-rows: 17vh 40vh;

        .title {
          align-items: ${({ $condRender }) =>
    $condRender ? "flex-start" : "flex-end"};
          align-self: end;
          width: 70%;
          margin: 0 auto;

          .icon {
            display: none;
            opacity: 0;
          }
        }

        .cards {
          grid-area: cards;
          display: grid;
          justify-items: end;
          width: 70%;
          margin: 0 auto;

          .card-container-added-value {
            display: block;
            position: relative;
            height: 300px;
            width: 100%;
          }

          .card-container-our-services {
            display: block;
            position: relative;
            height: 300px;
            width: 100%;
          }

          .card-added-value {
            position: absolute;
            transition: all 1s ease;

            &.list {
              align-items: flex-start;
              justify-content: flex-start;

              h2 {
                position: relative;
                padding: 1rem 0;
                margin-top: 3rem;
                margin-bottom: 1rem;

                &:after {
                  content: "";
                  background-color: var(--white);
                  position: absolute;
                  width: 60px;
                  bottom: 0;
                  left: 0;
                  height: 2px;
                }
              }
            }

            &.small {
              ${'' /* top: 37%; */}
              bottom: 0;
              height: 200px;
              width: 22%;
              right: 0;
            }

            &.large {
              top: 0;
              right: 45%;
              bottom: 0;
              width: 40%;
              background-color: var(--yellow-200);
              color: var(--white);
              font-size: 1.5em;
            }
          }
        }

        .card-our-services {
          position: absolute;
          transition: all 1s ease;

          &.list {
            align-items: flex-start;
            justify-content: flex-start;

            h2 {
              position: relative;
              padding: 1rem 0;
              margin-top: 3rem;
              margin-bottom: 1rem;

              &:after {
                content: "";
                background-color: var(--white);
                position: absolute;
                width: 60px;
                bottom: 0;
                left: 0;
                height: 2px;
              }
            }
          }

          &.small {
            top: unset;
            bottom: -5px;
            height: 200px;
            width: 7%;
            left: 0;
          }

          &.small ~ .small {
            top: unset;
            bottom: -5px;
            left: 20%;
            width: 7%;
          }

          &.large {
            top: 0;
            left: 45%;
            bottom: 0;
            width: 40%;
            background-color: var(--yellow-200);
            color: var(--white);
            font-size: 1.5em;
          }
        }
      }
    }

    .switch {
      position: absolute;
      bottom: -3px;
      ${({ $condRender }) => ($condRender ? `right: 0;` : `left: 0;`)}

      .switch-btn {
        animation: fadeIn 1s ease;
        ${({ $condRender }) =>
    $condRender ? "transform: scaleX(1);" : "transform: scaleX(-1);"}
        position: relative;
        cursor: pointer;

        .icon {
          width: 50px;
          height: 50px;
          position: absolute;
          right: 60px;
          bottom: 60px;
        }
      }
    }
  }
`;
