import styled, { keyframes } from "styled-components";

export const InfiniteSlider = ({ logos }) => {
  return (
    <InfiniteContainer>
      <ImgGroup>
        {logos.map((logo, index) => (
          <Img key={index} src={logo} alt="logo" />
        ))}
      </ImgGroup>
      <ImgGroup>
        {logos.map((logo, index) => (
          <Img key={index} src={logo} alt="logo" />
        ))}
      </ImgGroup>
    </InfiniteContainer>
  );
};

const InfiniteContainer = styled.div`
  display: flex;
  user-select: none;
  -webkit-user-select: none;
`;

const infiniteSliderAnimation = keyframes`
	0% { transform: translateX(0) }
	100% { transform: translateX(-100%) }
`;

const ImgGroup = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: calc(250px * 8);
  animation: ${infiniteSliderAnimation} 10s linear infinite;
`;

const Img = styled.img`
`;

export default InfiniteSlider;
