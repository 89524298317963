export const Menu = ({ className }) => {
  return (
    <svg
      width="35"
      height="21"
      viewBox="0 0 35 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M35 2.84375H0V0.65625H35V2.84375ZM35 20.3438H0V18.1562H35V20.3438ZM35 11.5762H0V9.40625H35V11.5762Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
