export const Actuarial = ({ fill = "#292E3D" }) => {
  return (
    <svg
      width="33"
      height="37"
      viewBox="0 0 33 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 3158">
        <path
          id="Vector"
          opacity="0.5"
          d="M2.83008 29.3189C2.83008 30.3989 3.70008 31.2689 4.78008 31.2689H29.9401C31.0201 31.2689 31.8901 30.3989 31.8901 29.3189V27.1289H2.83008V29.3189Z"
          fill={fill}
        />
        <path
          id="Vector_2"
          d="M11.9898 25.1614H8.33984C7.78984 25.1614 7.33984 24.7114 7.33984 24.1614V18.9414C7.33984 18.3914 7.78984 17.9414 8.33984 17.9414H11.9898C12.5398 17.9414 12.9898 18.3914 12.9898 18.9414V24.1614C12.9898 24.7114 12.5398 25.1614 11.9898 25.1614ZM9.33984 23.1614H10.9898V19.9414H9.33984V23.1614Z"
          fill={fill}
        />
        <path
          id="Vector_3"
          d="M26.3697 25.1594H22.7197C22.1697 25.1594 21.7197 24.7094 21.7197 24.1594V12.8594C21.7197 12.3094 22.1697 11.8594 22.7197 11.8594H26.3697C26.9197 11.8594 27.3697 12.3094 27.3697 12.8594V24.1594C27.3697 24.7094 26.9197 25.1594 26.3697 25.1594ZM23.7197 23.1594H25.3697V13.8594H23.7197V23.1594Z"
          fill={fill}
        />
        <path
          id="Vector_4"
          opacity="0.5"
          d="M12.8301 16.5C16.9722 16.5 20.3301 13.1421 20.3301 9C20.3301 4.85786 16.9722 1.5 12.8301 1.5C8.68794 1.5 5.33008 4.85786 5.33008 9C5.33008 13.1421 8.68794 16.5 12.8301 16.5Z"
          fill={fill}
        />
        <path
          id="Vector_5"
          d="M3.06965 20.4733L5.52331 18.0197C5.99584 17.5471 5.99742 16.7826 5.52684 16.312C5.05626 15.8414 4.29171 15.843 3.81918 16.3155L1.36552 18.7692C0.892985 19.2417 0.891402 20.0063 1.36198 20.4769C1.83257 20.9474 2.59711 20.9459 3.06965 20.4733Z"
          fill="#808080"
        />
        <path
          id="Vector_6"
          d="M29.93 8.89H21.32C21.26 4.26 17.48 0.5 12.83 0.5C8.18 0.5 4.37 4.28 4.33 8.93C2.91 9.15 1.82 10.36 1.82 11.84V16.88L0.64 18.06C0.23 18.47 0 19.03 0 19.62C0 20.21 0.23 20.77 0.65 21.18C0.98 21.51 1.4 21.71 1.83 21.79V29.33C1.83 30.96 3.15 32.28 4.78 32.28H14.24V34.51H11.95C11.4 34.51 10.95 34.96 10.95 35.51C10.95 36.06 11.4 36.51 11.95 36.51H22.76C23.31 36.51 23.76 36.06 23.76 35.51C23.76 34.96 23.31 34.51 22.76 34.51H20.47V32.28H29.93C31.56 32.28 32.88 30.96 32.88 29.33V11.84C32.88 10.21 31.56 8.89 29.93 8.89ZM6.33 8.89C6.39 5.36 9.28 2.5 12.82 2.5C16.36 2.5 19.25 5.35 19.31 8.89C19.31 8.93 19.31 8.97 19.31 9C19.31 9.66 19.21 10.29 19.03 10.89C18.46 12.77 17.05 14.28 15.24 15.01C15.16 15.03 15.08 15.07 15.01 15.11C14.32 15.36 13.58 15.5 12.81 15.5C9.88 15.5 7.4 13.55 6.59 10.89C6.41 10.29 6.31 9.66 6.31 9C6.31 8.96 6.31 8.92 6.31 8.89H6.33ZM18.17 16.96V23.17H16.52V16.96H18.17ZM3.83 11.84C3.83 11.39 4.15 11.03 4.56 10.93C4.85 12.16 5.41 13.29 6.16 14.25L5.49 14.92C5.44 14.97 5.41 15.04 5.37 15.1C4.86 14.93 4.31 14.92 3.82 15.12V11.84H3.83ZM2.36 19.77C2.28 19.85 2.15 19.85 2.07 19.77C2.02 19.72 2.01 19.66 2.01 19.62C2.01 19.58 2.01 19.52 2.07 19.47L3.84 17.7L4.53 17.01C4.53 17.01 4.62 16.95 4.68 16.95C4.74 16.95 4.79 16.97 4.83 17.01C4.88 17.06 4.89 17.12 4.89 17.16C4.89 17.2 4.88 17.26 4.83 17.31L3.84 18.3L2.37 19.77H2.36ZM18.47 34.51H16.24V32.28H18.47V34.51ZM30.89 29.33C30.89 29.85 30.46 30.28 29.94 30.28H4.78C4.26 30.28 3.83 29.85 3.83 29.33V28.14H30.89V29.33ZM30.89 26.14H3.83V21.12L6.23 18.72C6.65 18.3 6.88 17.75 6.88 17.16C6.88 16.91 6.83 16.67 6.75 16.45C6.81 16.41 6.87 16.39 6.91 16.34L7.57 15.67C9.02 16.81 10.84 17.5 12.82 17.5C13.4 17.5 13.97 17.43 14.52 17.32V24.16C14.52 24.71 14.97 25.16 15.52 25.16H19.17C19.72 25.16 20.17 24.71 20.17 24.16V15.95C20.17 15.4 19.72 14.95 19.17 14.95H18.87C19.96 13.84 20.75 12.45 21.11 10.88H29.93C30.45 10.88 30.88 11.31 30.88 11.83V26.13L30.89 26.14Z"
          fill={fill}
        />
        <path
          id="Vector_7"
          d="M17.4201 10.0002C16.8701 10.0002 16.4201 9.55016 16.4201 9.00016C16.4201 7.02016 14.8101 5.41016 12.8301 5.41016C12.2801 5.41016 11.8301 4.96016 11.8301 4.41016C11.8301 3.86016 12.2801 3.41016 12.8301 3.41016C15.9101 3.41016 18.4201 5.92016 18.4201 9.00016C18.4201 9.55016 17.9701 10.0002 17.4201 10.0002Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}