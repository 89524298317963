import { sizes } from "./sizes";

export const devices = {
    mobileS: `(width > ${sizes.mobileS}px)`,
    mobileM: `(width > ${sizes.mobileM}px)`,
    mobileL: `(width > ${sizes.mobileL}px)`,
    tablet: `(width > ${sizes.tablet}px)`,
    laptop: `(width > ${sizes.laptop}px)`,
    laptopL: `(width > ${sizes.laptopL}px)`,
    desktopS: `(width > ${sizes.desktopS}px)`,
    desktop: `(width > ${sizes.desktop}px)`,
}
