import styled from "styled-components";
import { browserRouter } from "../router";
import { NavLink } from "react-router-dom";
import { Menu } from "./ui/icons/Menu";
import { useState } from "react";
import { Close } from "./ui/icons/Close";

export const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const routes = browserRouter.routes[0].children;

  const handleShowMenu = () => {
    setTimeout(() => {
      setShowMenu(!showMenu)
    }, 100);
  };

  return (
    <Nav>
      <NavContainer>
        <NavLogo>
          <NavLink to="/">
            <Img src="/images/Logo1.svg" alt="logo" />
          </NavLink>
        </NavLogo>

        <NavMenu onClick={handleShowMenu}>
          <Menu />
          <MenuBox
            style={{
              visibility: showMenu ? "visible" : "hidden",
              opacity: showMenu ? 1 : 0,
            }}
          >
            <div className="menu-title">
              <h1>Menu</h1>
              <Close onClick={handleShowMenu} fill="#FFF"/>
            </div>
            <hr style={{ width: "100%" }}/>
            <ul>
              {routes.map(
                ({ path, name, id }) =>
                  name !== "" && (
                    <li key={name}>
                      <NavLink
                        key={id}
                        to={path}
                        className={name === "Contact us" ? "btn-yellow" : ""}
                      >
                        {name}
                      </NavLink>
                    </li>
                  )
              )}
            </ul>

            <NavLink to="/contact" className={`btn-yellow btn-contact`}>
              Contact Us
            </NavLink>
          </MenuBox>
        </NavMenu>

        <NavElements>
          <ul>
            {routes.map(
              ({ path, name, id }) =>
                name !== "" && (
                  <li key={name}>
                    <NavLink
                      key={id}
                      to={path}
                      className={name === "Contact us" ? "btn-yellow" : ""}
                    >
                      {name}
                    </NavLink>
                  </li>
                )
            )}
          </ul>
        </NavElements>

        <NavContact>
          <NavLink to="/contact" className={`btn-yellow`}>
            Contact Us
          </NavLink>
        </NavContact>
      </NavContainer>
    </Nav>
  );
};

export default NavBar;

// styles
const Nav = styled.nav``;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  max-width: 90%;
  margin: 0 auto;
  z-index: 2;
  top: 1em;
  left: 0;
  right: 0;

  @media (width > 1024px) {
    top: 3em;
  }
`;

const NavLogo = styled.div``;

const NavElements = styled.div`
  display: none;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;

    li a {
      margin: 0 1em;
      color: #fff;
      font-weight: 500;
      font-size: 1.2rem;
    }
  }


  @media (width > 1024px) {
    display: block;

    ul li a {
      margin: 0 .7em;
      font-size: 1.5rem;
    }
  }

  @media (width > 1440px) {
    ul li a {
      margin: 0 1.7em;
    }
  }
`;

const NavMenu = styled.div`
  @media (width > 1024px) {
    display: none;
  }
`;

const MenuBox = styled.div`
  display: flex;
  flex-flow: column;
  padding: 1em 1em;
  position: fixed;
  z-index: 2;
  top: 0px;
  right: 0px;
  background-color: var(--blue-200);
  border-radius: 1.3rem 0 0 1rem;
  background: linear-gradient(180deg, rgba(73, 88, 130, 0.85) 9.9%, rgba(42, 57, 100, 0.85) 57.81%, rgba(19, 22, 40, 0.85) 95.31%);
  box-shadow: 3px 7px 24px 17px rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  transition: visibility .3s, opacity 0.3s linear;

  .menu-title {
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-weight: 400;
    }

    svg {
      width: 22px;
    }
  }

  .btn-contact {
    color: var(--white);
    font-weight: 700;
    padding: 1em 0;
    text-align: center;
  }

  hr {
    margin-top: 5px;
  }

  .close {
    width: 2em;
    cursor: pointer;
  }

  ul {
    padding: 0 4em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
    align-items: center;
    margin: 1em 0;
    margin-bottom: 3em;
  }

  ul, li {
    list-style-type: none;
  }

  ul li a {
    color: var(--white);
  }
`

const NavContact = styled.div`
  display: none;

  a {
    color: #fff;
    font-weight: 700;
  }

  @media (width > 1024px) {
    display: block;
  }
`

const Img = styled.img``;
