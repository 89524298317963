export const Close = ({ fill = "#56555A" }) => {
  return (
    <svg
      className="close"
      width="45"
      height="46"
      viewBox="0 0 45 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 103">
        <path
          id="+"
          d="M22.5 0.5C10.0936 0.5 0 10.5936 0 23C0 35.4064 10.0936 45.5 22.5 45.5C34.9064 45.5 45 35.4064 45 23C45 10.5936 34.9064 0.5 22.5 0.5ZM30.6465 28.6996C30.814 28.8588 30.9479 29.0498 31.0404 29.2615C31.1329 29.4732 31.1821 29.7013 31.185 29.9323C31.188 30.1632 31.1447 30.3925 31.0576 30.6065C30.9706 30.8205 30.8416 31.0149 30.6782 31.1782C30.5149 31.3416 30.3205 31.4706 30.1065 31.5576C29.8925 31.6447 29.6632 31.688 29.4323 31.685C29.2013 31.6821 28.9732 31.6329 28.7615 31.5404C28.5498 31.4479 28.3588 31.314 28.1996 31.1465L22.5 25.448L16.8004 31.1465C16.4731 31.4574 16.0374 31.6282 15.5861 31.6224C15.1348 31.6166 14.7036 31.4347 14.3844 31.1156C14.0653 30.7964 13.8834 30.3652 13.8776 29.9139C13.8719 29.4626 14.0426 29.0269 14.3535 28.6996L20.052 23L14.3535 17.3004C14.0426 16.9731 13.8719 16.5374 13.8776 16.0861C13.8834 15.6348 14.0653 15.2036 14.3844 14.8844C14.7036 14.5653 15.1348 14.3834 15.5861 14.3776C16.0374 14.3719 16.4731 14.5426 16.8004 14.8535L22.5 20.552L28.1996 14.8535C28.5269 14.5426 28.9626 14.3719 29.4139 14.3776C29.8652 14.3834 30.2964 14.5653 30.6156 14.8844C30.9347 15.2036 31.1166 15.6348 31.1224 16.0861C31.1282 16.5374 30.9574 16.9731 30.6465 17.3004L24.948 23L30.6465 28.6996Z"
          fill={fill}
          fillOpacity="0.5"
        />
      </g>
    </svg>
  );
};
