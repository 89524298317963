export const Rectangle = ({ className }) => {
  return (
    <svg
    className={`${className}`}
      width="1047"
      height="776"
      viewBox="0 0 1047 776"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.85" filter="url(#filter0_d_722_15050)">
        <path
          d="M-127 0H715L1002.9 884H-127V0Z"
          fill="url(#paint0_linear_722_15050)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_722_15050"
          x="-127"
          y="0"
          width="1173.9"
          height="931"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="27" dy="30" />
          <feGaussianBlur stdDeviation="8.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_722_15050"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_722_15050"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_722_15050"
          x1="437.95"
          y1="0"
          x2="437.95"
          y2="884"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.390625" stopColor="#2A3964" />
          <stop offset="0.953125" stopColor="#131628" />
        </linearGradient>
      </defs>
    </svg>
  );
};
