export const Contact = () => {
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M18.375 0.25H2.625C1.92904 0.250744 1.26179 0.527545 0.769667 1.01967C0.277545 1.51179 0.000744481 2.17904 0 2.875V14.125C0.000744481 14.821 0.277545 15.4882 0.769667 15.9803C1.26179 16.4725 1.92904 16.7493 2.625 16.75H18.375C19.071 16.7493 19.7382 16.4725 20.2303 15.9803C20.7225 15.4882 20.9993 14.821 21 14.125V2.875C20.9993 2.17904 20.7225 1.51179 20.2303 1.01967C19.7382 0.527545 19.071 0.250744 18.375 0.25ZM17.7103 4.59203L10.9603 9.84203C10.8287 9.94436 10.6667 9.99991 10.5 9.99991C10.3333 9.99991 10.1713 9.94436 10.0397 9.84203L3.28969 4.59203C3.21038 4.53214 3.14377 4.45709 3.09372 4.37123C3.04367 4.28537 3.01118 4.19042 2.99815 4.0919C2.98511 3.99338 2.99179 3.89325 3.01778 3.79733C3.04378 3.70142 3.08858 3.61162 3.14958 3.53316C3.21058 3.45471 3.28656 3.38916 3.37312 3.34032C3.45967 3.29149 3.55506 3.26034 3.65376 3.24869C3.75245 3.23704 3.85248 3.24513 3.94802 3.27247C4.04357 3.29981 4.13272 3.34587 4.21031 3.40797L10.5 8.29984L16.7897 3.40797C16.947 3.2892 17.1447 3.23711 17.34 3.26296C17.5354 3.28881 17.7128 3.39053 17.8338 3.54612C17.9547 3.70171 18.0096 3.89866 17.9865 4.09439C17.9634 4.29011 17.8642 4.46888 17.7103 4.59203Z"
        fill="#464449"
      />
    </svg>
  );
};
