import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { browserRouter } from "./router"
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { RouterProvider } from 'react-router-dom';
import './index.css';

const client = new ApolloClient({
  uri: 'https://api.dev.dynamicreinsurance.com',
  cache: new InMemoryCache(),
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors)
    console.log('networkError', networkError)
  }
});

const container = document.getElementById('root');
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <ApolloProvider client={client}>
          <RouterProvider router={browserRouter} />
      </ApolloProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
