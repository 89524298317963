export const Industrial = ({ fill = "#292E3D" }) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M28.8301 20.7895H24.0601L24.8901 9.4895L28.0001 9.5395L28.8301 20.7895Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M35.7698 20.7897H31.0098L31.8598 9.22974H34.9198L35.7698 20.7897Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M18.3902 27.4294H6.49023V35.0694H18.3902V27.4294Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M21.8799 20.7893V35.0693H37.9599V20.7893H21.8799ZM28.6299 27.4293H24.9499V23.7493H28.6299V27.4293ZM34.8899 27.4293H31.2099V23.7493H34.8899V27.4293Z"
        fill={fill}
      />
      <path
        d="M34.89 28.4298H31.21C30.66 28.4298 30.21 27.9798 30.21 27.4298V23.7498C30.21 23.1998 30.66 22.7498 31.21 22.7498H34.89C35.44 22.7498 35.89 23.1998 35.89 23.7498V27.4298C35.89 27.9798 35.44 28.4298 34.89 28.4298ZM32.21 26.4298H33.89V24.7498H32.21V26.4298Z"
        fill={fill}
      />
      <path
        d="M37.96 19.7898H36.7L35.61 4.92976C35.57 4.40976 35.14 3.99976 34.61 3.99976H32.17C31.65 3.99976 31.21 4.39976 31.17 4.92976L30.08 19.7898H29.75L28.66 4.92976C28.62 4.40976 28.19 3.99976 27.66 3.99976H25.22C24.7 3.99976 24.26 4.39976 24.22 4.92976L23.13 19.7898H22.87V15.7698C22.87 15.4198 22.69 15.0898 22.38 14.9098C22.08 14.7298 21.71 14.7198 21.4 14.8798L13.43 19.1198V15.7598C13.43 15.4098 13.25 15.0798 12.94 14.8998C12.64 14.7198 12.27 14.7098 11.96 14.8698L2.53 19.9098C2.2 20.0798 2 20.4198 2 20.7898V35.0698C2 35.6198 2.45 36.0698 3 36.0698H37.96C38.51 36.0698 38.96 35.6198 38.96 35.0698V20.7898C38.96 20.2398 38.51 19.7898 37.96 19.7898ZM33.1 6.00976H33.68L33.85 8.30976H32.93L33.1 6.00976ZM32.78 10.3098H34L34.7 19.7898H32.09L32.79 10.3098H32.78ZM26.15 6.00976H26.73L26.9 8.30976H25.98L26.15 6.00976ZM25.83 10.3098H27.05L27.75 19.7898H25.14L25.84 10.3098H25.83ZM17.38 34.0698H7.49V28.4298H17.39V34.0698H17.38ZM18.38 26.4298H6.49C5.94 26.4298 5.49 26.8798 5.49 27.4298V34.0698H4V21.3898L11.44 17.4298V20.7898C11.44 21.1398 11.62 21.4698 11.93 21.6498C12.23 21.8298 12.6 21.8398 12.91 21.6798L20.88 17.4398V34.0798H19.39V27.4398C19.39 26.8898 18.94 26.4398 18.39 26.4398L18.38 26.4298ZM36.95 34.0698H22.87V21.7898H36.95V34.0698Z"
        fill={fill}
      />
      <path
        d="M28.6302 28.4298H24.9502C24.4002 28.4298 23.9502 27.9798 23.9502 27.4298V23.7498C23.9502 23.1998 24.4002 22.7498 24.9502 22.7498H28.6302C29.1802 22.7498 29.6302 23.1998 29.6302 23.7498V27.4298C29.6302 27.9798 29.1802 28.4298 28.6302 28.4298ZM25.9502 26.4298H27.6302V24.7498H25.9502V26.4298Z"
        fill={fill}
      />
    </svg>
  );
};

