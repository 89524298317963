export const Linkedin = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="entypo-social:linkedin-with-circle">
        <path
          id="Vector"
          d="M18 3.08179C9.76082 3.08179 3.08179 9.76082 3.08179 18C3.08179 26.2393 9.76082 32.9183 18 32.9183C26.2393 32.9183 32.9183 26.2393 32.9183 18C32.9183 9.76082 26.2393 3.08179 18 3.08179ZM14.3482 24.1834H11.3272V14.4616H14.3482V24.1834ZM12.8191 13.2682C11.8649 13.2682 11.248 12.5922 11.248 11.7561C11.248 10.903 11.8836 10.2472 12.8579 10.2472C13.8323 10.2472 14.429 10.903 14.4476 11.7561C14.4476 12.5922 13.8323 13.2682 12.8191 13.2682ZM25.3815 24.1834H22.3605V18.7957C22.3605 17.5416 21.9223 16.69 20.8299 16.69C19.9954 16.69 19.4996 17.2666 19.2805 17.8213C19.1997 18.0187 19.1795 18.2984 19.1795 18.5766V24.1818H16.157V17.5618C16.157 16.3482 16.1182 15.3334 16.0778 14.4601H18.7024L18.8408 15.8105H18.9014C19.2992 15.1765 20.2735 14.241 21.9037 14.241C23.8912 14.241 25.3815 15.5727 25.3815 18.4352V24.1834Z"
          fill="#3BABF1"
        />
      </g>
    </svg>
  );
};
