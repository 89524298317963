import { createBrowserRouter } from "react-router-dom";
import { Layout } from "./components";
import { lazy } from "react";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const History = lazy(() => import("./pages/History"));
const Product = lazy(() => import("./pages/Product"));
const Services = lazy(() => import("./pages/Services"));
const Team = lazy(() => import("./pages/Team"));
const News = lazy(() => import("./pages/News"));
const JoinUs = lazy(() => import("./pages/JoinUs"));
const Contact = lazy(() => import("./pages/Contact"));

export const browserRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        Component: Home,
        name: "",
      },
      {
        path: "/about",
        Component: About,
        name: "About",
      },
      {
        path: "/history",
        Component: History,
        name: "History",
      },
      {
        path: "/product",
        Component: Product,
        name: "Product",
      },
      {
        path: "/services",
        Component: Services,
        name: "Services",
      },
      {
        path: "/team",
        Component: Team,
        name: "Team",
      },
      {
        path: "/news",
        Component: News,
        name: "News",
      },
      {
        path: "/join-us",
        Component: JoinUs,
        name: "Join us",
      },
      {
        path: "/contact",
        Component: Contact,
        name: "",
      },
    ],
  },
]);
